import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Img from "gatsby-image";
import SEO from "../components/seo";

const AboutTheSeriesPage = ({ data }) => (
  <Layout>
    <SEO
      title="The Kalb Report | About the Series"
      keywords={[`kalb`, `kalb report`, `the kalb report`]}
    />
    <section className="small-page-heading">
      <div className="container">
        <h1>About the Series</h1>
      </div>
    </section>
    <section
      className="about-2 section bg-gray about-the-program-section"
      id="about"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <p>
              Through conversations with leaders who shape media, public policy
              and culture in America, the distinguished journalist Marvin Kalb
              explores the vital role of the press in our democracy and the
              transformation of journalism in the 21st century.
            </p>
            <p>
              Guests have included U.S. Supreme Court Justices Antonin Scalia
              and Ruth Bader Ginsburg, Bob Woodward and Carl Bernstein, Rupert
              Murdoch, Diane Sawyer, Katie Couric, Christiane Amanpour, Walter
              Cronkite, Hillary Clinton, Roger Ailes, Bob Costas, Ken Burns,
              Judy Woodruff, Gwen Ifill, Nobel Prize winner Elie Wiesel, Cokie
              Roberts, and Apollo 11 astronaut Michael Collins.
            </p>
            <p>
              Now in its 28th season, The Kalb Report is jointly produced by the
              National Press Club Journalism Institute, the George Washington
              University (GWU), The Shorenstein Center on Media, Politics, and
              Public Policy at Harvard University, University of Maryland Global
              Campus (UMGC), the Philip Merrill College of Journalism at the
              University of Maryland, and the Gaylord College of Journalism at
              the University of Oklahoma.
            </p>
            <p>
              The Kalb Report is underwritten by a grant from Ethics and
              Excellence in Journalism Foundation.
            </p>
            <p>
              The series is distributed nationally by{" "}
              <a
                href="https://www.aptonline.org/catalog/KALB-REPORT-THE-Season-13"
                target="_blank"
                noreferrer
                noopener
              >
                American Public Television
              </a>{" "}
              and is seen on public television stations across the country.
              Maryland Public Television serves as presenting station. The
              series also airs nationally on Sirius-XM Satellite Radio. Each
              program is also live-streamed by The National Press Club at
              press.org
            </p>
            <p>
              Moderator Marvin Kalb was the last correspondent personally hired
              at CBS News by the legendary Edward R. Murrow and served as Chief
              Diplomatic Correspondent for the CBS Evening News with Walter
              Cronkite. He later moderated NBC Meet the Press and was the
              founding director of Harvard’s Shorenstein Center. In addition to
              moderating The Kalb Report, he is the Edward R. Murrow Professor
              Emeritus at Harvard’s Kennedy School and a nonresident senior
              fellow with the Brookings Institution.
            </p>
            <p>
              The executive producer of The Kalb Report series is Michael
              Freedman, a senior vice president and journalist-in-residence at
              University of Maryland Global Campus and professorial lecturer in
              journalism at GWU. Mr. Freedman is the former general manager of
              CBS Radio Network. The senior producer is Heather Date, a vice
              president at UMGC and former CNN producer. Producers include Dick
              Golden, Tiina Kreek, Scott Graham, Lindsay Underwood, Bob Ludwig,
              Kat Bugg, and Avi Feinberg. The web editor is Bryan Kane. The
              director is Shelly Schwartz.
            </p>
            <p>
              The Kalb Report series was most recently honored with a 2018 Gold
              World Medal in the New York Festivals International Radio Awards
              competition. The series also received Gold World Medals in 2015
              and 2012, when it was honored with the overall Grand Award.
            </p>
            <div
              style={{
                marginTop: 50,
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div>
                <a
                  href="https://www.aptonline.org/"
                  target="_blank"
                  noopener
                  noreferrer
                >
                  <img src={data.aptLogo.publicURL} width={250} />
                </a>
              </div>
              <div>
                <a
                  href="http://www.mpt.org/"
                  target="_blank"
                  noopener
                  noreferrer
                >
                  <Img
                    fixed={data.mptLogo.childImageSharp.fixed}
                    fadeIn={false}
                    width={250}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 offset-lg-1">
            <div className="sidebar-images d-lg-block d-none ">
              <div className="sidebar-image">
                <Img
                  fluid={data.dianeSawyer.childImageSharp.fluid}
                  fadeIn={false}
                />
              </div>
              <div className="sidebar-image">
                <Img
                  fluid={data.tedKoppel.childImageSharp.fluid}
                  fadeIn={false}
                />
              </div>
              <div className="sidebar-image">
                <Img
                  fluid={data.allTheNews.childImageSharp.fluid}
                  fadeIn={false}
                />
              </div>
              <div className="sidebar-image">
                <Img
                  fluid={data.onDemand.childImageSharp.fluid}
                  fadeIn={false}
                />
              </div>
              <div className="sidebar-image d-sm-none d-md-block d-xl-none">
                <Img
                  fluid={data.walterIsaacson.childImageSharp.fluid}
                  fadeIn={false}
                />
              </div>
              <div className="sidebar-image d-sm-none d-md-block d-xl-none">
                <Img
                  fluid={data.rupertMurdoch.childImageSharp.fluid}
                  fadeIn={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export const sidebarImage = graphql`
  fragment sidebarAboutImage on File {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const query = graphql`
  query {
    dianeSawyer: file(relativePath: { eq: "diane_sawyer.jpg" }) {
      ...sidebarAboutImage
    }

    tedKoppel: file(relativePath: { eq: "ted_koppel.jpg" }) {
      ...sidebarAboutImage
    }

    allTheNews: file(relativePath: { eq: "all_the_news.jpg" }) {
      ...sidebarAboutImage
    }

    walterIsaacson: file(relativePath: { eq: "walter_isaacson.jpg" }) {
      ...sidebarAboutImage
    }

    onDemand: file(relativePath: { eq: "on_demand.jpg" }) {
      ...sidebarAboutImage
    }

    rupertMurdoch: file(relativePath: { eq: "rupert_murdoch.jpg" }) {
      ...sidebarAboutImage
    }
    aptLogo: file(relativePath: { eq: "apt.svg" }) {
      publicURL
    }
    mptLogo: file(relativePath: { eq: "mpt.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;

export default AboutTheSeriesPage;
